import React, { ChangeEvent, FC, useEffect, useState } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import InputMask from "react-input-mask"
import { Box, InputAdornment, MenuItem, OutlinedTextFieldProps, TextField } from "@material-ui/core"

import { useAppSelector } from "../../../../../hooks/storeHooks"
import store from "../../../../../store"
import { selectClinicSettings } from "../../../../../store/clinic/clinicSettings.selectors"
import CountrySelect from "../../../../commonFormItems/countrySelect/CountrySelect.component"
import SexCheckbox from "../../../../commonFormItems/customCheckboxes/SexCheckbox.component"
import DisabledFormFieldTooltip from "../../../../commonFormItems/disabledFormFieldTooltip/DisabledFormFieldTooltip.component"
import TextFieldController from "../../../../commonFormItems/textFieldController/TextFieldController.component"
import TimeZoneSelect from "../../../../commonFormItems/timeZoneSelect/TimeZoneSelect.component"
import {
  disabledFormFieldTooltipInputProps, resolveDisableInputProps
} from "../../../../informationPanel/fillUserDataInformationPanel/FillUserDataInformationPanel.component"
import { checkFeatureAvailability } from "../../../../../store/clinic/clinicSettings.utils"
import { revertDateFormat } from "../../../../../utils/dayJs/ManipulateDate"
import { getDocumentTypesForCountry, setProperDefaultDocumentType } from "../../userPersonalData/UserPersonalData.utils"
import { MainCountriesTypes, UserDataType, UserDocumentData, UserDocumentType } from "../../../../../store/user/user.types"
import { useUserPersonalDataEditModalStyles } from "../../userPersonalData/UserPersonalDataEditModal/UserPersonalDataEditModal.styles"

interface UserParentPersonalDataEditFormProps {
  userParentData: UserDataType["parent"];
  userParentDocumentData: UserDocumentData;
  loading: boolean;
  underageUserChecked?: boolean
}

const UserParentPersonalDataEditForm: FC<UserParentPersonalDataEditFormProps> = ({
  userParentData,
  userParentDocumentData,
  loading,
  underageUserChecked = false,
}) => {
  const {t} = useTranslation()
  const form = useFormContext()
  const { hide } = useUserPersonalDataEditModalStyles()
  const userData = store.getState().user.userData
  const [patientHavePesel, setPatientHavePesel] = useState(false)
  const [documentTypes, setDocumentTypes] = useState<UserDocumentData[]>(getDocumentTypesForCountry(userParentData?.nationality || userData?.nationality || MainCountriesTypes.PL))
  const [showDocumentTypeInput, setShowDocumentTypeInput] = useState((userParentData?.nationality || userData?.nationality) !== MainCountriesTypes.PL)
  const [identityNumberFieldLabel, setIdentityNumberFieldLabel] = useState(t("user:identityCardNumberLabel"))
  const { frontendSettings: { modules } } = useAppSelector(selectClinicSettings)
  const isDifferentDateFormatEnabled = modules.includes("enable_different_date_format")
  const canPatientEditDataUntilFirstConsultationFinished = modules.includes("enable_data_update") && !userData?.isSensitiveDataEditingDisabled

  const handleChangeNationality = (onChange: (value: string) => void) => (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value

    form.setValue("parentDocumentType", "")
    setDocumentTypes(getDocumentTypesForCountry(newValue))
    setShowDocumentTypeInput(newValue !== MainCountriesTypes.PL)
    onChange(newValue)
  }

  const handleIdentityNumberFieldChange = (documentTypeValue: string) => {
    if (checkFeatureAvailability().isUniqaApiIntegrationEnabled) {
      if (documentTypeValue === UserDocumentType.IDENTITY_CARD_NUMBER) {
        setIdentityNumberFieldLabel(t("user:identityCardNumber"))
        return
      }

      if (documentTypeValue === UserDocumentType.PASSPORT) {
        setIdentityNumberFieldLabel(t("user:passportNumber"))
        return
      }

      setIdentityNumberFieldLabel(t("user:identityCardNumber"))
    }
  }

  const handleChangeDocumentType = (onChange: (value: string) => void) => (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value

    const isPeselDocumentType = newValue === UserDocumentType.PESEL

    setPatientHavePesel(isPeselDocumentType)

    if (canPatientEditDataUntilFirstConsultationFinished && isPeselDocumentType) {
      form.setValue("parentNationality", "PL")
    }

    handleIdentityNumberFieldChange(newValue)
    onChange(newValue)
  }

  useEffect(() => {
    const documentTypeValue = setProperDefaultDocumentType(userParentData?.nationality || userData?.nationality, userParentData?.pesel ? UserDocumentType.PESEL : userParentData?.identity_document_type) || documentTypes[0]?.value
    const showPeselField = documentTypeValue === UserDocumentType.PESEL && (userParentData?.pesel || !userParentData?.identity_number)

    form.setValue("parentDocumentType", documentTypeValue)
    setPatientHavePesel(!!showPeselField)
    handleIdentityNumberFieldChange(documentTypeValue)
  }, [documentTypes])

  useEffect(() => {
    if (underageUserChecked) {
      const selectedParentNationality = form.getValues("parentNationality")
      setDocumentTypes(getDocumentTypesForCountry(selectedParentNationality)) 
      setShowDocumentTypeInput(selectedParentNationality !== MainCountriesTypes.PL)
    }
  },[underageUserChecked])

  return (
    <Box>
      <TextFieldController
        name="parentFirstName"
        label={t("user:name")}
        disabled={loading}
        isRequired
        InputProps={
          resolveDisableInputProps(
            () => disabledFormFieldTooltipInputProps(userParentData?.first_name, loading),
            canPatientEditDataUntilFirstConsultationFinished
          )
        }
      />

      <TextFieldController
        name="parentLastName"
        label={t("user:surname")}
        disabled={loading}
        isRequired
        InputProps={
          resolveDisableInputProps(
            () => disabledFormFieldTooltipInputProps(userParentData?.last_name, loading),
            canPatientEditDataUntilFirstConsultationFinished
          )
        }
      />

      <Controller
        name="parentNationality"
        control={form.control}
        render={({
          field: {onChange, value},
        }) => (
          <CountrySelect
            id="parentNationality"
            label={t("user:country")}
            placeholder={t("user:country")}
            fullWidth
            required
            disabled={loading}
            value={value}
            onChange={handleChangeNationality(onChange)}
            InputProps={ canPatientEditDataUntilFirstConsultationFinished ? undefined : {
              disabled: loading || !!userParentData?.pesel || !!userParentData?.identity_number,
              endAdornment: !!userParentData?.pesel || !!userParentData?.identity_number
                ? (
                  <InputAdornment position="end">
                    <DisabledFormFieldTooltip/>
                  </InputAdornment>
                ) : undefined,
            }}
          />
        )}
      />

      {showDocumentTypeInput && (
        <Controller
          name="parentDocumentType"
          control={form.control}
          render={({
            field: {onChange, value},
            fieldState: {error, invalid},
          }) => (
            <TextField
              value={value}
              select
              label={t("user:documentType")}
              placeholder={t("user:documentType")}
              fullWidth
              onChange={handleChangeDocumentType(onChange)}
              error={invalid}
              helperText={error?.message}
              InputProps={ canPatientEditDataUntilFirstConsultationFinished ? undefined : {
                disabled: loading || !!userParentData?.identity_document_type,
                endAdornment: !!userParentData?.identity_document_type
                  ? (
                    <InputAdornment position="end">
                      <DisabledFormFieldTooltip/>
                    </InputAdornment>
                  ) : undefined,
              }}
            >
              {documentTypes.map((option) => (
                <MenuItem
                  // ES Documents exceptions: hide option which was set just to show label
                  className={
                    option.value === UserDocumentType.ES_IDENTITY_CARDS_TOGETHER
                          || (
                            option.value === UserDocumentType.PESEL
                              && checkFeatureAvailability().isUniqaApiIntegrationEnabled
                              && userData?.nationality !== MainCountriesTypes.PL
                          )
                      ? hide
                      : undefined
                  }
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      )}
      {patientHavePesel ? (
        <TextFieldController
          name="parentPesel"
          label={t("user:pesel")}
          disabled={loading}
          isRequired
          InputProps={
            resolveDisableInputProps(
              () => disabledFormFieldTooltipInputProps(userParentDocumentData.value, loading),
              canPatientEditDataUntilFirstConsultationFinished
            )
          }
        />
      ) : (
        <>
          <TextFieldController
            name="parentIdentityNumber"
            label={String(identityNumberFieldLabel)}
            disabled={loading}
            isRequired
            InputProps={
              resolveDisableInputProps(
                () => disabledFormFieldTooltipInputProps(userParentDocumentData.value, loading),
                canPatientEditDataUntilFirstConsultationFinished
              )
            }
          />
          <Controller
            name="parentBirthDate"
            control={form.control}
            render={({
              field: {onChange, value},
              fieldState: {error, invalid},
            }) => (
              <InputMask
                id="parentBirthDate"
                mask={isDifferentDateFormatEnabled ? "99-99-9999" : "9999-99-99"}
                value={isDifferentDateFormatEnabled ? revertDateFormat(value, false) : value}
                onChange={onChange}
                disabled={loading}
              >
                {(props: OutlinedTextFieldProps) => (
                  <TextField
                    helperText={error?.message}
                    error={invalid}
                    disabled={loading}
                    fullWidth
                    label={t("user:" + (isDifferentDateFormatEnabled ? "differentFormatBirthDate" : "birthDate"))}
                    InputProps={
                      resolveDisableInputProps(
                        () => disabledFormFieldTooltipInputProps(userParentDocumentData.value, loading),
                        canPatientEditDataUntilFirstConsultationFinished
                      )
                    }
                    {...props}
                  />
                )}
              </InputMask>
            )}
          />
          <SexCheckbox
            name="parentGender"
            disabled={loading || (userParentData?.gender ?? null) !== null}
            initialValue={userParentData?.gender}
          />
        </>
      )}

      <Controller
        name="parentDefaultTimezone"
        control={form.control}
        render={({
          field: {onChange, value},
          fieldState: {error, invalid},
        }) => (
          <TimeZoneSelect
            id="parentDefaultTimezone"
            label={t("user:defaultTimezone")}
            placeholder={t("user:defaultTimezone")}
            fullWidth
            disabled={loading}
            value={value || null}
            onChange={onChange}
            error={invalid}
            helperText={error?.message}
            InputProps={
              resolveDisableInputProps(
                () => disabledFormFieldTooltipInputProps(userParentData?.default_timezone, loading),
                canPatientEditDataUntilFirstConsultationFinished
              )
            }
          />
        )}
      />
    </Box>
  )
}

export default UserParentPersonalDataEditForm
