import React, {FC,useEffect} from "react"
import {useTranslation} from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import {Box, Grid, useMediaQuery, useTheme} from "@material-ui/core"

import useIsB2BClinic from "../../../hooks/useIsB2BClinic"
import { selectVouchers, selectVouchersLoading } from "../../../store/vouchers/vouchers.selectors"
import { getVouchers } from "../../../store/vouchers/vouchers.slice"
import LoaderBox from "../../common/loaderBox/LoaderBox.component"
import VoucherBox from "../../voucher/voucherBox/VoucherBox.component"
import UserDataInfoSection from "../common/UserDataInfoSection.component"
import { groupVouchers } from "./UserVouchers.utils"
import { VoucherModel } from "../../../store/vouchers/vouchers.types"
import {useUserProfilePageStyles} from "../../../pages/user/UserProfilePage.styles"

interface UserVouchersProps {}

const UserVouchers: FC<UserVouchersProps> = () => {
  const {t} = useTranslation()
  const theme = useTheme()
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"))
  const gridClasses = useUserProfilePageStyles()
  const dispatch = useDispatch()
  const { B2BClinic } = useIsB2BClinic()

  const vouchersDataLoading: boolean = useSelector(selectVouchersLoading)
  const vouchersList: VoucherModel[] | null = useSelector(selectVouchers)

  const visibleVouchers = groupVouchers(vouchersList)

  // vouchers splitted into two columns on lg up
  const leftColVouchersLgUp = visibleVouchers.map((voucher, idx) => (
    idx % 2 ? null : <VoucherBox key={voucher.id} voucher={voucher}/>
  ))
  const rightColVouchersLgUp = visibleVouchers.map((voucher, idx) => (
    idx % 2 ? <VoucherBox key={voucher.id} voucher={voucher}/> : null
  ))
  // e/o vouchers splitted into two columns on lg up

  useEffect(() => {
    dispatch(getVouchers())
  }, [])

  if (vouchersDataLoading) {
    return (
      <Grid item xs={12} lg={B2BClinic ? 12 : 8} className={gridClasses.gridItem}>
        <Box className={gridClasses.gridItemVoucherContentBox}>
          <UserDataInfoSection title={ t("finances:vouchers:title") }>
            <LoaderBox size={theme.spacing(8)} thickness={2}/>
          </UserDataInfoSection>
        </Box>
      </Grid>
    )
  }

  if (visibleVouchers.length === 0) {
    return (
      <Grid item xs={12} lg={B2BClinic? 12: 8} className={gridClasses.gridItem}>
        <Box className={gridClasses.gridItemVoucherContentBox}>
          <UserDataInfoSection title={ t("finances:vouchers:title") }>
            <Box>
              <i>{ t("finances:vouchers:noVouchers") }</i>
            </Box>
          </UserDataInfoSection>
        </Box>
      </Grid>
    )
  }

  return (
    <>
      <Grid item xs={12} lg={B2BClinic ? 6 : 4} className={gridClasses.gridItem}>
        <Box className={gridClasses.gridItemVoucherContentBox}>
          <UserDataInfoSection
            title={ t("finances:vouchers:title") }
          >
            { isLgUp
              ? leftColVouchersLgUp // splitted into two columns
              : visibleVouchers.map((voucher) => (
                <VoucherBox key={voucher.id} voucher={voucher}/> // all vouchers in this column
              ))
            }
          </UserDataInfoSection>
        </Box>
      </Grid>

      { isLgUp && (
        <Grid item lg={B2BClinic ? 6 : 4} className={gridClasses.gridItem}>
          <Box className={gridClasses.gridItemVoucherContentBox}>
            <UserDataInfoSection>
              <Box>
                { rightColVouchersLgUp }
              </Box>
            </UserDataInfoSection>
          </Box>
        </Grid>
      )}
    </>
  )
}

export default UserVouchers
