import i18next from "i18next"
import * as yup from "yup"

import { APP_PASSWORD_REGEXP } from "../../../../constants/defaults"
import { UserPasswordChangeFormValues } from "./UserPasswordChange.types"

export const userPasswordChangeValidationSchema = (): yup.SchemaOf<UserPasswordChangeFormValues> => {
  return yup.object().shape({
    oldPlainPassword: yup.string().required(i18next.t("errors:required")),
    plainPassword: yup.string()
      .required(i18next.t("errors:required"))
      .matches(APP_PASSWORD_REGEXP, i18next.t("user:plainPasswordRequirements")),
    plainPasswordRepeat: yup.string()
      .required(i18next.t("errors:required"))
      .test("password match", i18next.t("errors:passwordMatchError"), (plainPasswordRepeat, testContext) => {
        return plainPasswordRepeat === testContext.parent.plainPassword
      }),
  })
}
