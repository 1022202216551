import React, { FC, useState } from "react"
import { Controller,useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Box, Button, TextField } from "@material-ui/core"
import { AxiosError } from "axios"
import i18next from "i18next"

import api from "../../../api/api"
import { postVouchersDataConfig } from "../../../api/routes"
import { useAppSelector } from "../../../hooks/storeHooks"
import { selectUserId } from "../../../store/session/session.selectors"
import { selectIsAddNewVoucherModalOpen } from "../../../store/vouchers/vouchers.selectors"
import { getVouchers, setAddNewVoucherModalOpen } from "../../../store/vouchers/vouchers.slice"
import AppDialog from "../../common/appDialog/AppDialog.component"
import ButtonLoader from "../../common/buttonLoader/ButtonLoader.component"
import GlobalFormErrorMessage from "../../commonFormItems/globalFormErrorMessage/GlobalFormErrorMessage.component"
import { handleReactHookFormErrors } from "../../../utils/handleErrors"
import { AssignVoucherDateType } from "../../../store/vouchers/vouchers.types"

interface GlobalErrorResponse {
  message: string; // translated error message
}

interface AddNewVoucherModalProps {}

const AddNewVoucherModal: FC<AddNewVoucherModalProps> = () => {
  const {t} = useTranslation()
  const dispatch = useDispatch()

  const [loading, setLoading] = useState<boolean>(false)
  const isAddNewVoucherModalOpen: boolean = useSelector(selectIsAddNewVoucherModalOpen)

  const userId = useAppSelector(selectUserId)
  const form = useForm<AssignVoucherDateType>({
    defaultValues: {
      patientId: userId!,
      code: ""
    }
  })

  const [globalError, setGlobalError] = useState<AxiosError<GlobalErrorResponse> | null>(null)
  const globalErrorMessage = globalError?.response?.data.message
    ? t(`errors:${globalError?.response?.data.message}`)
    : t("errors:unknownError")

  const handleClosePopup = () => {
    form.reset()
    setGlobalError(null)
    dispatch(setAddNewVoucherModalOpen(false))
  }

  const onSubmit = form.handleSubmit(async (values) => {
    setLoading(true)

    try {
      await api.request({
        ...postVouchersDataConfig(i18next.language ?? "pl"),
        data: values
      })
      handleClosePopup()
      dispatch(getVouchers())
      setLoading(false)
    } catch (e) {
      console.error(e)
      if (e.response?.data?.errors) {
        handleReactHookFormErrors<AssignVoucherDateType>(e.response.data.errors, form)
      }
      // Some errors are not associated with form fields, we call them "global"
      // They stay out of form scope, so we keep them in useState hook
      if (e.response?.data?.message) {
        setGlobalError(e)
      }
      // e/o "global" errors
      setLoading(false)
    }
  })

  return (
    <AppDialog
      open={isAddNewVoucherModalOpen}
      onClose={handleClosePopup}
      title={t("finances:vouchers:addNewVoucher")}
      closeButton={false}
    >
      <form
        autoComplete="off"
        noValidate
        onSubmit={onSubmit}
      >
        {globalError && (
          <GlobalFormErrorMessage
            message={globalErrorMessage}
          />
        )}
        <Controller
          name="code"
          control={form.control}
          rules={{required: `${t("errors:required")}`}}
          render={({field: {onChange, value}, fieldState: {error, invalid}}) => (
            <TextField
              value={value}
              label={t("finances:vouchers:discountCode")}
              placeholder={t("finances:vouchers:discountCode")}
              disabled={loading}
              error={invalid}
              helperText={error?.message}
              fullWidth
              onChange={onChange}
            />
          )}
        />

        <Box textAlign="right" mt={1}>
          <Button
            disabled={loading}
            variant={"contained"}
            color={"primary"}
            type="submit"
            endIcon={loading && <ButtonLoader/>}
          >
            {t("finances:vouchers:submit")}
          </Button>
        </Box>
      </form>
    </AppDialog>
  )
}

export default AddNewVoucherModal
