export enum ApiError {
  SIGNAL_IDUNA_INTEGRATION_NO_INSURANCE = 47,
  FILE_UPLOAD_ERROR = 10,
  UNCOVERED_EXAMS_IN_INSURANCE_PACKAGE = 64,
  SIGNAL_IDUNA_FORGOT_PASSWORD_ACCESS_TOKEN_EXPIRED = 67,
  SIGNAL_IDUNA_INTEGRATION_NOT_AVAILABLE = 68,
  SIGNAL_IDUNA_INTEGRATION_VER_REQ = 69,
  SIGNAL_IDUNA_INTEGRATION_AVAILABLE_CONF_REQ = 70,
  SIGNAL_IDUNA_INTEGRATION_NOT_AVAILABLE_ERROR = 71,
  SIGNAL_IDUNA_INTEGRATION_NO_VALID_INSURANCE_PROCEDURES = 72,
  ALREADY_ACTIVE_DECLARATION = 13,
  DIGITAL_SIGNATURE_ERROR = 78,
  DIGITAL_SIGNATURE_REDIRECT_URL_MISSING = 79,
  DIGITAL_SIGNATURE_IDENTITY_ERROR = 80,
  S7HEALTH_NO_BOOKING_PERMISSION = 82,
  S7HEALTH_INSURANCE_SUSPENDED = 83,
  S7HEALTH_INSURANCE_INACTIVE = 84,
  ALLIANZ_EWKA_INSURANCE_INACTIVE = 85,
  ALLIANZ_ONYX_INSURANCE_INACTIVE = 90
}
