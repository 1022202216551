import i18next from "i18next"
import * as yup from "yup"

import { APP_PASSWORD_REGEXP } from "../../../../constants/defaults"
import { UserForcePasswordChangeFormValues } from "./UserForcePasswordChange.types"

export const userForcePasswordChangeValidationSchema = (): yup.SchemaOf<UserForcePasswordChangeFormValues> => {
  return yup.object().shape({
    plainPassword: yup.string()
      .required(i18next.t("errors:required"))
      .matches(APP_PASSWORD_REGEXP, i18next.t("user:plainPasswordRequirements")),
    plainPasswordRepeat: yup.string()
      .required(i18next.t("errors:required"))
      .test("password match", i18next.t("errors:passwordMatchError"), (plainPasswordRepeat, testContext) => {
        return plainPasswordRepeat === testContext.parent.plainPassword
      }),
  })
}
