import { VoucherModel } from "../../../store/vouchers/vouchers.types"

export const groupVouchers = (vouchers: VoucherModel[] | null): VoucherModel[] => {
  if (!vouchers || vouchers.length === 0) return []
  
  const groupedMap = new Map<string, VoucherModel>()
  
  vouchers
    .filter(voucher => voucher.code !== "POZ")
    .forEach(voucher => {
      const key = voucher.code
      
      if (groupedMap.has(key)) {
        const existingVoucher = groupedMap.get(key)!
        
        if (voucher.is_valid && !existingVoucher.is_valid) {
          groupedMap.set(key, voucher)
        } else if (voucher.is_valid === existingVoucher.is_valid) {
          const existingDate = existingVoucher.valid_to ? new Date(existingVoucher.valid_to) : new Date(0)
          const currentDate = voucher.valid_to ? new Date(voucher.valid_to) : new Date(0)
          
          if (currentDate > existingDate) {
            groupedMap.set(key, voucher)
          }
        }
      } else {
        groupedMap.set(key, voucher)
      }
    })
  
  return Array.from(groupedMap.values())
    .sort((a, b) => !a.is_valid && b.is_valid ? 1 : (!b.is_valid && a.is_valid ? -1 : 0))
}
